import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import PortableText from '../components/PortableText'

export const query = graphql`
  {
    sanitySupportPage {
      pageTitle
      _rawBody
    }
  }
`

export default function Support({ data: { sanitySupportPage } }) {
  return (
    <Layout title='Support' page='support' url='/support'>
      <h1 className='font-h1'>{sanitySupportPage.pageTitle}</h1>
      <PortableText blocks={sanitySupportPage._rawBody} />
    </Layout>
  )
}
